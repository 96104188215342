<template>
  <v-card :loading="loading" class="profile-info pb-3 pt-3 px-3">
    <div v-if="ipData">
      <v-list dense color="transparent">
        <v-list-item v-for="(key, i) in objectKeys(ipData)" :key="i">
          <v-list-item-content v-if="key !== 'location'">
            <v-list-item-title>{{ capitalize(key) }}</v-list-item-title>
            <span @click="copy(ipData[key])">{{ ipData[key] }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="ipData && ipData.latitude">
          <v-list-item-content>
            <div style="height: 400px">
              <l-map :zoom="zoom" :center="center" style="height: 100%; width: 100%" ref="myMap">
                <l-tile-layer :url="url"></l-tile-layer>
                <l-circle-marker :lat-lng="center" :radius="19" color="red" />
              </l-map>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="no_link" link target="_blank" :href="srcIpMaps(ipData)">
          <v-list-item-content>
            <v-list-item-title>Check in GoogleMaps</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div>
              <v-btn class="no_link" small :to="checkDehashedG()" target="_blank" width="100%" :dark="false">
                {{ translate('checkDehashed') }}
              </v-btn>
              <v-btn class="mt-2 no_link" small :to="checkExperimentalG()" target="_blank" width="100%" :dark="false">{{ translate('checkExperimental') }}</v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-card>
</template>

<script>
import rService from '../../services/RequestService/RequestServiceSetup';
import { copyTextToClipboard } from '../../services/Copy';
import { capitalize } from 'lodash';
import L from 'leaflet';
import { LMap, LTileLayer, LCircleMarker } from 'vue2-leaflet';

export default {
  name: 'IPInfoContent',
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
  },
  props: {
    ip: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      zoom: 70,
      center: [47.41322, -1.219482],
      ipData: [],
      loading: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    };
  },
  beforeMount() {
    this.getIpDate();
  },
  methods: {
    translate(item) {
      return this.$vuetify.lang.t('$vuetify.Home.' + item);
    },
    checkExperimentalG() {
      return {
        name: 'experimentalCheck',
        params: {
          type: 'lastip',
          entry: this.ip,
        },
      };
    },
    checkDehashedG() {
      return {
        name: 'dehashedCheck',
        params: {
          type: 'ip_address',
          entry: this.ip,
        },
      };
    },
    srcIpMaps({ latitude, longitude }) {
      return `https://maps.google.com/maps?q=${latitude},${longitude}&hl=es&z=14&amp;output=embed`;
    },
    iframeMaps({ latitud, longitude }) {},
    getLink({ latitude, longitude }) {
      return `https://www.google.com.uy/maps/@${latitude},${longitude}`;
    },
    capitalize(item) {
      return capitalize(item.replace(/\_/g, ' '));
    },
    objectKeys(item) {
      return Object.keys(item);
    },
    copy(text) {
      const res = copyTextToClipboard(text);
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.$copied(`<p>Copied to clipboard:<br>${text}</p>`);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err, text);
      }
    },
    cF(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async getIpDate() {
      this.ipData = await rService.post('ip/info', { ip: this.ip });
      if (this.ipData && this.ipData.latitude) {
        this.center = [this.ipData.latitude, this.ipData.longitude];
      }
      this.loading = false;
    },
  },
};
</script>

<style>
body .vue2leaflet-map {
  position: relative;
  height: 50vh !important;
}
</style>