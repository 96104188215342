import md5 from 'md5';
import sha1 from 'sha1';
import sha256 from 'sha256';
class Hashing {
  public hash_types = [
    {
      name: 'MD5',
      value: 'md5'
    },
    {
      name: 'SHA1',
      value: 'sha1'
    },
    {
      name: 'SHA256',
      value: 'sha256'
    }
  ]
  revertHash(self) {
    self.hash_applied = false;
    self.toSearch = self.backup_search;
    self.backup_search = '';
  }
  applyHash(self) {
    if (self.hash_applied) {
      return this.revertHash(self);
    }
    self.hash_applied = true;
    self.backup_search = self.toSearch;
    self.toSearch = (this.getFunction(self))(self.toSearch);
    self.hash_applied = self.hash_type;
  }
  getFunction(self) {
    let f;
    switch (self.hash_type) {
      case 'md5':
        f = md5;
        break;
      case 'sha1':
        f = sha1;
        break;
      case 'sha256':
        f = sha256;
        break;
      case 'sha256x2':
        f = sha256.x2;
        break;
      default:
        break;
    }
    return f;
  }
  applyHashMulti(self) {
    const f = this.getFunction(self);
    const lines = self.lines;
    const new_lines = lines.map(el => f(el))
    return new_lines;
  }
}

const hashing = new Hashing();
export default hashing;